import React from "react"
import ArticleContentBody from "../../components/pages/ArticleDetails/ArticleContentBody"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const EmailsAutomaticallyDeletedFromMailbox = () => {
  return (
    <Layout>
      <Seo
        title="Emails Automatically Deleted from Mailbox"
        description={`Users may experience emails automatically being deleted from their mailbox immediately upon arrival.`}
      />

      {/* This slug is matched with articles in Content */}
      <ArticleContentBody slug={`emails-automatically-deleted-from-mailbox`} />
    </Layout>
  )
}

export default EmailsAutomaticallyDeletedFromMailbox
